import { useState } from 'react';
import { ICompaniesFilter, ICompany } from '../../appBackend/useCompanies';
import { Urls } from '../../backend/urls';
import { useLazyLoad } from '../../appBackend/useLazyLoad';
import AppSelect from './AppSelect';
import { useTranslation } from 'react-i18next';
import { formatCompanyAddress } from '../../formatters/formatCompanyAddress';

function getCompanyOptionTitle(company?: ICompany | null) {
  if(!company) {
    return '';
  }

  let res = company.companyName;
  const formattedAddress = formatCompanyAddress(company.billingAddress);
  if (formattedAddress) {
    res += ' - ' + formattedAddress;
  }
  return res;
}

export default function CompaniesSelect({
  company,
  setCompany,
  searchMode = false,
  placeholderTranslationKey = 'SelectCompany',
  searchPlaceholderTranslationKey = 'SearchCompanies',
  incomingValue,
  filter,
}: {
  company?: ICompany | null;
  setCompany: (company: ICompany | null) => void;
  searchMode?: boolean;
  placeholderTranslationKey?: string;
  searchPlaceholderTranslationKey?: string;
  incomingValue?: string;
  filter?: (company: ICompany) => boolean;
}) {
  const { t } = useTranslation();
  const [companySearch, setCompanySearch] = useState('');

  const {
    items: companies,
    loadMore: loadMoreCompanies,
    lastPageLoaded: lastPageOfCompaniesLoaded,
    loading: isCompaniesLoading,
  } = useLazyLoad<ICompany>(Urls.Companies, {
    search: companySearch,
    excludeE2ECompanies: true,
  } as ICompaniesFilter);

  const getFilteredCompanies = () => {
    return filter ? companies.filter(filter) : companies;
  };

  const companiesOptions = getFilteredCompanies()
    .map(getCompanyOptionTitle);

  const setSelectedCompanyFromOption = (companyTitle: string) => {
    const company = companies.find(c => getCompanyOptionTitle(c) === companyTitle);
    setCompany(company || null);
  };

  return (
    <AppSelect
      className='app-form-input'
      outlineSearch
      searchMode={ searchMode }
      outlineSearchPlaceholder={ t(searchPlaceholderTranslationKey) }
      onOutlineSearchChange={ e => {
        setCompanySearch(e.target.value);
      } }
      onClosed={ () => {
        setCompanySearch('');
      } }
      onOptionsScrolledToBottom={ loadMoreCompanies }
      areOptionsLoading={ isCompaniesLoading }
      lastPageLoaded={ lastPageOfCompaniesLoaded }
      showNoOptions
      incomingValue={ incomingValue || getCompanyOptionTitle(company) || ''}
      options={ companiesOptions }
      placeholder={ t(placeholderTranslationKey) }
      onOptionSelected={ setSelectedCompanyFromOption }
    />
  );
}
