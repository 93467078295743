import { useTranslation } from 'react-i18next';
import { AppPopupLayout } from '../../shared/appUIFramework/popup/AppPopupLayout';
import EmailSendIcon from '../../assets/EmailSentDark.svg';
import { closePopup } from '../../shared/appUIFramework/popup/AppPopup';

export default function MaintenanceEmailsSendConfirmationPopup() {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      onHeaderClose={ () => closePopup() }
      headerTitle={ 'EmailConfirmation' }
      popupBody={ 
        <div className='app-d-flex app-flex-column app-align-items-center'>
          <img
            src={ EmailSendIcon }
            alt='Email Sent Icon'
          />
          <span className='app-font-20 app-uppercase app-weight-600 app-text-align-center app-mt-30'>
            { t('EmailSent') }
          </span>
          <span className='app-font-20 app-weight-600 app-text-align-center app-mt-30'>
            <span>{ t('ScheduledMaintenanceEmailsSentToAllUsers') }</span>
            <span>.</span>
          </span>
        </div>
       }
    />
  );
}
