import { ServiceRegion } from '../../../shared/appBackend/useMetrics';
import { useTranslation } from 'react-i18next';
import AppPopupMenu from '../../../shared/appUIFramework/components/AppPopupMenu';
import '../ServiceHealth.scss';

function getRegionTranslationKey(region: ServiceRegion) {
  const keys: Record<ServiceRegion, string> = {
    [ServiceRegion.All]: 'AllRegions',
    [ServiceRegion.Benelux]: 'BeneluxNLLUBE',
    [ServiceRegion.UnitedKingdom]: 'UnitedKingdomUL',
    [ServiceRegion.Ireland]: 'IrelandIR',
    [ServiceRegion.UnitedStates]: 'UnitedStatesUS',
    [ServiceRegion.Netherlands]: 'NetherlandsNL',
    [ServiceRegion.Belgium]: 'BelgiumBE',
    [ServiceRegion.Luxembourg]: 'LuxembourgLU',
    [ServiceRegion.Germany]: 'GermanyDE',
    [ServiceRegion.France]: 'FranceFR',
    [ServiceRegion.SouthAfrica]: 'SouthAfricaZA',
    [ServiceRegion.Canada]: 'CanadaCA',
    [ServiceRegion.Sweden]: 'SwedenSE',
    [ServiceRegion.Norway]: 'NorwayNO',
    [ServiceRegion.Denmark]: 'DenmarkDK',
  };

  return keys[region];
}

function RegionLabel({ region }: { region: ServiceRegion }) {
  const { t } = useTranslation();
  return <>{t(getRegionTranslationKey(region))}</>;
}

function enumToArray(en: any): any[] {
  const keys = Object.keys(en);
  const stringIsNumber = (p: string) => !isNaN(Number(p));

  return keys.filter(stringIsNumber);
}

export default function RegionsDropdown({ region, setRegion }: {
  region: ServiceRegion;
  setRegion: (region: ServiceRegion) => void;
}) {
  return (
    <div className='app-d-flex app-regions-dropdown'>
      <AppPopupMenu
        options={enumToArray(ServiceRegion).map(p => +p)}
        onOptionSelected={setRegion}
        getOptionLabel={option => {
          const isOptionSelected = option === region;
          return (
            <div className='app-d-flex app-gap-30'>
              <input type='checkbox' checked={isOptionSelected} readOnly />
              <RegionLabel region={option} />
            </div>
          );
        }}
        render={isHidden => {
          return (
            <div className='app-d-flex app-justify-content-between app-align-items-center app-gap-10'>
              <RegionLabel region={region} />
              <div className='app-arrow-wrapper'>
                <div className={isHidden ? 'app-down-arrow' : 'app-up-arrow'} />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
