import { useState } from 'react';
import './EntryAnalytics.scss';
import HomeShell from '../components/HomeShell';
import { ServiceRegion } from "../../../shared/appBackend/useMetrics";
import RegionsDropdown from "../components/RegionsDropdown";
import CompaniesWidget from './components/CompaniesWidget';
import SitesWidget from './components/SitesWidget';
import UsersWidget from './components/UsersWidget';
import CallRateWidget from './components/CallRateWidget';
import { EntryAnalyticsContext } from './EntryAnalyticsContext';
import { EntryRegion, EntryRegionType } from '../../../shared/backend/urls';

function serviceRegionToApiRegion(serviceRegion: ServiceRegion): EntryRegionType {
  const map: Record<ServiceRegion, EntryRegionType> = {
    [ServiceRegion.All]: null,
    [ServiceRegion.Benelux]: "benelux",
    [ServiceRegion.UnitedKingdom]: EntryRegion.UnitedKingdom,
    [ServiceRegion.Ireland]: EntryRegion.Ireland,
    [ServiceRegion.UnitedStates]: EntryRegion.UnitedStates,
    [ServiceRegion.Netherlands]: EntryRegion.Netherlands,
    [ServiceRegion.Belgium]: EntryRegion.Belgium,
    [ServiceRegion.Luxembourg]: EntryRegion.Luxembourg,
    [ServiceRegion.Germany]: EntryRegion.Germany,
    [ServiceRegion.France]: EntryRegion.France,
    [ServiceRegion.SouthAfrica]: EntryRegion.SouthAfrica,
    [ServiceRegion.Canada]: EntryRegion.Canada,
    [ServiceRegion.Sweden]: EntryRegion.Sweden,
    [ServiceRegion.Norway]: EntryRegion.Norway,
    [ServiceRegion.Denmark]: EntryRegion.Denmark,
  }

  return map[serviceRegion];
}

export default function EntryAnalytics() {
  const [region, setRegion] = useState<ServiceRegion>(ServiceRegion.All);

  return <HomeShell activeTab="entry-analytics">
    <RegionsDropdown region={region} setRegion={setRegion} />
    <EntryAnalyticsContext.Provider value={{
      entryRegion: serviceRegionToApiRegion(region)
    }}>
      <div className="app-analytics-widgets">
        <CompaniesWidget />
        <SitesWidget />
      </div>
      <div className="app-d-flex app-w-50pcnt app-gap-30">
        <UsersWidget />
        <CallRateWidget />
      </div>
    </EntryAnalyticsContext.Provider>
  </HomeShell>;
}
