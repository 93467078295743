import {useTranslation} from "react-i18next";
import {useFormatNumber} from "../../../../shared/formatters/formatNumber";
import UsersIcon from "../../../../assets/icons/home page icons/Entry users.svg";
import {useTotalCount} from "../../../../shared/appBackend/useMetrics";
import {useContext} from "react";
import {EntryAnalyticsContext} from "../EntryAnalyticsContext";
import AppShowLoading from "../../../../shared/appUIFramework/components/AppShowLoading";

export default function UsersWidget() {
  const {t} = useTranslation();
  const {entryRegion} = useContext(EntryAnalyticsContext);
  const totalCount = useTotalCount(entryRegion);
  const formatNumber = useFormatNumber();
  return <div className="app-home-widget app-home-widget-users">
    <div className="app-home-widget-title app-mb-46">
      <span className="app-home-widget-title-text">{t('Users')}</span>
    </div>
    <div className="app-home-users-icon app-mb-25">
      <img src={UsersIcon} alt='entry users icon'/>
    </div>
    <div className="app-home-users-count app-mb-10">
      <AppShowLoading inline showLoading={totalCount == null}>
        {formatNumber(totalCount?.mobileUsersCount)}
      </AppShowLoading>
    </div>
    <div className="app-text-align-center app-uppercase app-mb-25 app-font-12 app-weight-600">
      {t('EntryAppUsers')}
    </div>
  </div>
}
