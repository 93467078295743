import { ReactComponent as GrowthDecreaseIcon } from '../../../assets/icons/home page icons/Growth decrease icon.svg';
import { ReactComponent as GrowthIncreaseIcon } from '../../../assets/icons/home page icons/Growth UP.svg';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';
import { formatPercents } from '../../../shared/formatters/formatPercents';
import { useTranslation } from "react-i18next";

export default function AppPercentGrowth({ growthPercents, growthMessage }: {
  growthPercents?: number | null,
  growthMessage: string
}) {
  const { t } = useTranslation();
  return <div
    className="app-percent-growth"
  >
    {growthPercents != null && growthPercents >= 0 && <GrowthIncreaseIcon />}
    {growthPercents != null && growthPercents < 0 && <GrowthDecreaseIcon />}
    <AppShowLoading inline showLoading={growthPercents == null}>
      <span
        className="app-percent-growth-percents"> {growthPercents == null
          ? t('N_A')
          : formatPercents(growthPercents * 100)}&nbsp; </span>
    </AppShowLoading>
  </div>;
}
