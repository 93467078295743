import { useEffect, useState } from 'react';
import { getUrlWithQueryParams } from '../backend/http/http';
import { Urls } from '../backend/urls';
import { wsGet } from '../backend/websockets/websockets';
import { ISite } from './useSites';

export interface IDevice {
  name: string;
  type: string;
  serialNumber: string;
  status: 'Online' | 'Offline';
}

interface IHardwareDevice {
  deviceName: string;
  deviceType: string;
  group: string;
  deviceId: string;
  serialNumber: string;
  isOnline: boolean;
}

interface IHardwareRequest {
  hardwareSummary: IHardwareDevice[];
}

export function useDevices(site: ISite | null): {
  devices: IDevice[] | undefined;
  loading: boolean;
  isOffline: boolean;
} {
  const [devices, setDevices] = useState<IDevice[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    if (!site) {
      setLoading(false);
      setDevices(undefined);
    } else {
      const wsUrl = getUrlWithQueryParams(Urls.HardwareSummary(site.id), { companyId: site.companyId });
      const wsNegotiateUrl = Urls.HardwareNegotiate(site.id);
      setLoading(true);
      wsGet<IHardwareRequest>(wsUrl, wsNegotiateUrl)
        .then(data => {
          setDevices(
            data.hardwareSummary
              .filter(device => {
                const isPanel = device.deviceType === 'Standard Panel' || device.deviceType === 'Touch Panel';
                return isPanel;
              })
              .map(device => {
                return {
                  name: device.deviceName,
                  type: device.deviceType,
                  serialNumber: device.serialNumber,
                  status: device.isOnline ? 'Online' : 'Offline',
                };
              }),
          );
          setIsOffline(false);
        })
        .catch((error: unknown) => {
          console.error('Error fetching hardware summary', error);
          setDevices([]);
          setIsOffline(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [site]);

  return { devices, loading, isOffline };
}
