const envUrl = process.env.REACT_APP_ENVIRONMENT ? `/${process.env.REACT_APP_ENVIRONMENT}` : '';

const baseUrl = `https://entry.paxtoncloud.com${envUrl}/api/v1/`;
const coreBaseUrl = `https://core.paxtoncloud.com${envUrl}/api/v1/`;

export enum EntryRegion {
  UnitedKingdom = 'UnitedKingdom',
  Ireland = 'Ireland',
  UnitedStates = 'UnitedStates',
  Netherlands = 'Netherlands',
  Belgium = 'Belgium',
  Luxembourg = 'Luxembourg',
  Germany = 'Germany',
  France = 'France',
  SouthAfrica = 'SouthAfrica',
  Canada = 'Canada',
  Sweden = 'Sweden',
  Norway = 'Norway',
  Denmark = 'Denmark',
}

export type EntryRegionType = EntryRegion | "benelux" | null;

export type FunctionAppType = 'user-mgmt';

export const Urls = {
  TotalCount: (region: EntryRegionType) => {
    return `${baseUrl}analytics/totalCount${region ? `?region=${region}` : ''}`;
  },
  ChangesForLastMonth: (region: EntryRegionType) => {
    return `${baseUrl}analytics/changesForLastMonth${region ? `?region=${region}` : ''}`;
  },
  GetEmails: `${baseUrl}companies/whitelist`,
  UpdateEmails: `${baseUrl}companies/whitelist`,
  ServiceHealthSummary: `${baseUrl}serviceHealth/summary`,
  ServiceHealthDetails: (functionApp: FunctionAppType) => {
    return `${baseUrl}serviceHealth/details/${functionApp}`;
  },
  Companies: `${baseUrl}companies/list`,
  CompaniesLength: `${baseUrl}companies/count`,
  CompaniesUnblockAccounts: (companyId: string) => `${baseUrl}companies/${companyId}/unblock`,
  CompaniesChangeWhiteListedStatus: (companyId: string) => `${baseUrl}/companies/${companyId}/whitelist/status`,
  Sites: (companyId: string) => `${baseUrl}sites/adminPortal/sites?companyId=${companyId}`,
  SitesTransfer: `${baseUrl}sites/transfer`,
  HardwareSummary: (siteId: string) => `${baseUrl}hardware/summary/${siteId}`,
  HardwareNegotiate: (siteId: string) => `${baseUrl}hardware/negotiate/${siteId}`,
  HardwareFirmware: (siteId: string, panelSerial: string) => `${baseUrl}hardware/${siteId}/${panelSerial}/version`,
  HardwareFirmwareNegotiate: (siteId: string) => `${baseUrl}hardware/firmware/negotiate/${siteId}`,
  CallTrackerEventsStartListen: (siteId: string, serialNumber: string, enableTestMode: boolean) => `${baseUrl}diagnostics/start?siteId=${siteId}&panelSerial=${serialNumber}&enableTestMode=${enableTestMode}`,
  CallTrackerEventsNegotiate: (siteId: string) => `${baseUrl}diagnostics/negotiate?siteId=${siteId}`,
  EmailLog: `${baseUrl}entry-admin/users/email-log`,
  EmailLogTotals: `${baseUrl}entry-admin/users/email-log-totals`,
  Invoices: `${coreBaseUrl}invoices/adminPortal`,
  InvoicesTotalNumber: `${coreBaseUrl}invoices/adminPortal/total`,
};
